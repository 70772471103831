import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Newsletter from '../components/Newsletter';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import AboutImages from '../components/AboutImages';
import {AiFillFacebook} from 'react-icons/ai';
import {GoCheck} from 'react-icons/go';
import Helmet from 'react-helmet';
import {FaGithub, FaTwitter} from 'react-icons/fa';

function ThanksLanding () {
  const aboutImages = AboutImages ();
  const screenshot = useStaticQuery (
    graphql`
      query {
        
        bitcoin:file(relativePath: {eq: "newsletter/1G5.png"}) {
          childImageSharp {
            fixed(width: 250, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
        hotmail:file(relativePath: {eq: "newsletter/mail-hotmail-icon.png"}) {
          childImageSharp {
            fixed(width: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
        yahoo:file(relativePath: {eq: "newsletter/yahoo-icon.png"}) {
          childImageSharp {
            fixed(width: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
      }`
  );
  return (
    <Layout>
      <SEO
        keywords={[`About`, 'Diego Valle', 'Diego Valle-Jones']}
        title="Thanks for subscribing"
        description=""
      />
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">

        <GoCheck
          size={40}
          className="container justify-center"
          color="#90EE90"
        />
        <h1 className="text-center">
          Thanks For Signing Up!
        </h1>
        <p className="bg-gray">
          <b>Your shapefiles are on the way.</b>
          {' '}
          Check your inbox (or spam/junk folder) for an email with the details in a couple minutes. While you wait, I recommend you take a few seconds to offer your support.
        </p><br />

        <p>
          <br />Thanks so much,<br />
          Diego Valle-Jones
        </p>
        <section className="p-4 container">
          <div className="flex flex-wrap text-center sm:justify-center md:justify-center">

            <div className="md:w-1/3 py-4 md:px-0 mb-4 md:mb-0">
              <div className="non-semantic-protector">
                <h3 className="text-2xl mb-3 font-heading ribbon">
                  STEP <span className="text-3xl">1</span>
                </h3>
              </div>
              <p className="text-gray-900 leading-relaxed">
                <a
                  className="no-underline"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://blog.diegovalle.net/2013/06/shapefiles-of-mexico-agebs-manzanas-etc.html"
                >
                  <button className="tracking-wider mx-auto block text-center inline-flex items-center shadow bg-blue-500 text-white hover:bg-blue-400 focus:shadow-outline focus:outline-none font-bold py-2 px-6 rounded shadow">
                    <AiFillFacebook size={32} /> <span> Share</span>
                  </button>
                </a>
                <br />

                <br /><b>Hook your sharing up</b>

              </p>
            </div>

            <div className="md:w-1/3 py-4 md:px-0 mb-4 md:mb-0 md:border-l">
              <div className="non-semantic-protector">
                <h3 className="text-2xl mb-3 font-heading ribbon">
                  STEP <span className="text-3xl">2</span>
                </h3>
              </div>
              <p className="text-gray-900 leading-relaxed">
                <div className="tracking-wider mx-auto block text-center inline-flex items-center shadow bg-blue-500 text-white hover:bg-blue-400 focus:shadow-outline focus:outline-none font-bold py-2 px-6 rounded shadow">
                  <a
                    className="twitter-follow-button twitter-follow-button-error"
                    href="https://twitter.com/diegovalle"
                    data-show-count="true"
                    data-lang="en"
                    data-size="large"
                    data-dnt="true"
                    data-twitter-extracted-i1591474104045715122="true"
                  >
                    <FaTwitter color="white" size={32} />
                  </a>
                </div><br/>
                <b>Follow me on Twitter</b>
                <br />
                <br /><a
                  className="twitter-follow-button twitter-follow-button-error"
                  href="https://twitter.com/diegovalle"
                  data-show-count="true"
                  data-lang="en"
                  data-size="large"
                  data-dnt="true"
                  data-twitter-extracted-i1591474104045715122="true"
                >
                  Follow @diegovalle
                </a>
                <br /><br />

                Don't miss the best visualization tweets and any updates to the shapefiles by following me on twitter

              </p>
            </div>

            <div className="md:w-1/3 py-4 md:px-0 mb-4 md:mb-0  md:border-l">

              <div className="non-semantic-protector">
                <h3 className="text-2xl mb-3 font-heading ribbon">
                  STEP <span className="text-3xl">3</span>
                </h3>
              </div>

              <p className="text-gray-900 leading-relaxed">
                <b>Donate Bitcoin</b>
              </p>
              <a
                className="text-xs"
                href="bitcoin:1G5zRq62eLVZm9rJBGCnwCMdSmTFMCV14i"
              >
                1G5zRq62eLVZm9rJBGCnwCMdSmTFMCV14i
              </a>

              <Img
                fixed={screenshot.bitcoin.childImageSharp.fixed}
                loading="lazy"
                className="object-none object-center"
              />

              <p>Help me defray costs by making a contribution</p>
            </div>

          </div>
        </section>

      </main>
    </Layout>
  );
}

export default ThanksLanding;
